body {
 overflow-x: hidden;
}


#root {
    background-color: rgba(255,255,255,0);
    /* color: white; */
}

.MuiSlider-track {
    height: 4px !important;
}

.MuiSlider-rail{
    height: 3px !important;
}

/* .MuiSlider-markLabelActive {
    color: white !important;
}

.MuiSlider-markLabel {
    color: white !important;
} */

#connect-btn{
    background-image: url("../../assets/images/ConnectedWallet.png");
}

#mint-button{
    background-image: url("../../assets/images/MintButton.png");
    /* cursor: not-allowed;
    filter: opacity(.6); */
}