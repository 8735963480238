#root {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  /* Main CSS */
  
  .App {

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: lowercase;
  
  
  }
  
  .gap {
    height: 50px;
    width: 100%;
  }
  


.DescMint {
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #510c0c;
    font-size: 40px;
    letter-spacing: 2.5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    /* padding-top: 50px; */
    margin-left: auto;
    margin-right: auto;
    text-transform: lowercase;
}








.walletButton {
  font-family: 'Indie Flower', cursive;
  font-size: 30px;
}

.walletButton2 {
  font-family: 'Indie Flower', cursive;
  font-size: 50px;
}
  
  
  .SubTitle {
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #510c0c;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  h3 {
    font-size: 4rem;
  }
  
  
  /* Titles  */
  
  
  .HeaderLogo {
    width: 55%;
    height: auto;  
  }
  
  .RoadMapLogo {
    width: 35%;
    /* height: auto;   */
  }
  
  .BioLogo {
    width: 35%;
    /* height: auto;   */
  }
  
  
  .AboutLogo {
    width: 35%;
    /* height: auto;   */
  }


  .WhatAreLogo {

    width: 45%;
    /* height: auto;   */
  }
  
  .TwitterLogo {
    cursor: pointer;
  }

  .EtherscanLogo{
    height: 32px;
    width: 32px;
  }

 

  .socialLinks {
    display: flex;
    flex-direction: row;
    gap: 2rem;

  }


  .metavate-p {
    font-size: 2rem;
    position: relative;
    left: 0%;
    top: 40px;    
    font-family: 'Indie Flower', cursive;

  }
  
  /* Sliders  */
  
  /* .slide-track {
    display: flex;
    position: relative;
    top: 575px;
    width: calc(550px * 18);
    animation: scroll 40s linear infinite;
    overflow: hidden;
  }
  
  @keyframes scroll {
    0% {
      transfrom: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 6));
    }
  }
  
  
  
  
  .slide {
    height: 500px;
    width: 700px;
    display: flex;
    align-items: center;
    padding: 5px;
    overflow: hidden;
   
    
  } */
  
  
  
  
  
  
  
  
  
  
  /* YouTube Videos  */
  
  .video {
    height: 500px;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    /* padding-top: 25px; */
    /* padding-bottom: 25px; */
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media screen and (max-width: 860px) {
  
    .Desc {
      font-family: 'Indie Flower', cursive;
      font-weight: 700;
      font-style: normal;
      font-size: 20px;
      letter-spacing: 2.5px;
      color: #510c0c;     
      justify-content: center;
      align-items: center;
      text-align: center;
       margin-left: auto;
      margin-right: auto;
      padding-left: 5%;
      padding-right: 5%;
      text-transform: lowercase;
    }
    
 
  
  .DescMint2 {
      font-family: 'Indie Flower', cursive;
      font-weight: 700;
      font-style: normal;
      color: #510c0c;
      font-size: 20px;
      letter-spacing: 2.5px;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 5%;   
      margin-left: auto;
      margin-right: auto;
      /* background: #27272751; */
      border-radius: 10px;
      text-transform: lowercase;
  }
  
  
  .DescMint3 {
    font-family: 'Indie Flower', cursive;
    font-weight: 700;
    font-style: normal;
    color: #510c0c;
    font-size: 30px;
    letter-spacing: 2.5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5%;   
    margin-left: auto;
    margin-right: auto;
    /* background: #27272751; */
    border-radius: 10px;
    text-transform: lowercase;
  }



    .video {
      height: 230px;
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      background: white;
    }
  
    .HeaderLogo {
      width: 100%;
      height: auto;  
    }
    
    .RoadMapLogo {
      width: 85%;
      /* height: auto;   */
    }
    
    .BioLogo {
      width: 85%;
      /* height: auto;   */
    }
    
    
    .AboutLogo {
      width: 85%;
      /* height: auto;   */
    }
  
  
    .WhatAreLogo {
      width: 85%;
      /* height: auto;   */
    }


  
  }
  
  .background {
    position: absolute;
    z-index: -100;

  }

  .EnterButton {
   width: 250px;
   max-width: 450px;
   border-radius: 10px;
   background: rgba(0,0,0,.5);
   backdrop-filter: blur(10px);
   border: 1px solid rgba(255,255,255,.3);
   padding: .5rem 1rem;
   position: absolute;
  }

  .EnterButton a {

    /* background: black; */
    /* padding: 1rem 2rem; */
   }