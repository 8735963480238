@import url("https://fonts.googleapis.com/css?family=Carrois+Gothic");

@font-face {
  font-family: 'matrix-code';
  src: url('http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.eot?#iefix') format('embedded-opentype'), 
       url('http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.woff') format('woff'), 
       url('http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.ttf')  format('truetype'),
       url('http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.svg#svgFontName') format('svg');
}

@font-face {
    font-family: Art;
    src: url('../assets/fonts/OCR.ttf');
  }

  @font-face {
    font-family: Art2;
    src: url('../assets/fonts/big_noodle_titling.ttf');
  }

.AppContainer {
    width: 100%;
    background: white;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



.DarkContainer {
    
  /* background: black; */
  font-family: 'Indie Flower', cursive;
  color: #510c0c;

    /* background: black; */
    z-index: 500;
    cursor: url(https://ani.cursors-4u.net/cursors/cur-13/cur1161.ani), url(https://ani.cursors-4u.net/cursors/cur-13/cur1161.png), auto !important;
  }


  .DarkContainer h1 {
    width: 50%;
  }

  .background-container {
    display: flex;
    justify-content: center;
    min-width: 100vw;
    /* min-height: 80vh; Set a minimum height to fill the viewport */
    background: url('../assets/images/Background3.png') no-repeat center bottom fixed;
    background-size: cover;
  }
  
.AppContainer2 {
  height: auto;
  padding-top: 10rem;

  display: flex;
}


.NavLogo a {
    display: flex;
    width: 80px;
    color: white;
    margin: auto;
    background: #141414;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.NavLogo a:hover {
    background: #59d600;
    transition: .5s ease-in;
    color: black;
}

.StoryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    text-align: center;
    color: white;
    margin: auto;

}

.fontArt {
  font-family: Art;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(15px);
    text-transform: uppercase;
    /* padding: 1rem 5rem; */
    /* font-size: 2.5rem; */
    /* border-radius: 30px; */
    border: 2px solid rgba(255,255,255,.1)
}


.fontArtNum {
  font-family: Art2;
    background: rgba(0,0,0,.6);
    backdrop-filter: blur(15px);
    text-transform: uppercase;
    /* padding: 1rem 5rem; */
    /* font-size: 2.5rem; */
    /* border-radius: 30px; */
    border: 2px solid rgba(255,255,255,.1)
}


.StoryContainer p {
    width: 100%;
    margin: auto;
}

.StoryCaption2 p {
    width: 100%;
    /* font-size: 3rem; */
    /* padding: 0 5rem; */
    /* margin: 50px auto; */
    color: #C8F600;
    text-align: center;
    
}

.StoryCaption3 p {
    width: 100%;
    /* font-size: 3rem; */
    /* padding: 0 5rem; */
    margin: 50px auto;
    color: #C8F600;
    text-align: center;

    
}

.StoryCaption4 p {
    width: 100%;
    font-size: 2rem;
    padding: 0 1rem;
    margin: 50px auto;
    color: #C8F600;
    text-align: center;
}


.StoryCaption5 p {
    width: 100%;
    font-size: 2rem;
 
    margin: 50px auto;
    color: #C8F600;
    text-align: center;
    
    
}


.StoryCaption {
    color: #C8F600;
    text-align: center;
    font-size: 40px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}

.StoryCaption p {
  padding: 3rem;
}

.StoryArt img {
    width: 100vw;
    z-index: -1;
}

.StoryBlock {
    /* margin: 400px 0 0 0; */
}

.FullScreen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.StoryButtons {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 2rem
}

/* .StoryButtons a {
    width: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    color: black;
    background: white;
    padding: 10px 20px;
} */


/* .StoryButtons a:hover {
    width: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    background: #59d600;
    padding: 10px 20px;
    transform: scale(1.3);
    transition: .2 ease-in;
} */


.buttons {
    width: 250px;
    height: auto;
}

.EndButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}


.fontAlt {
    /* font-family: 'Indie Flower', cursive; */
    font-weight: 700;
}

/* Custom CSS for the popup modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  
  .modal {
    background-color: rgba(0,0,0,.2);
    backdrop-filter: blur(15px);
    width: 40%;
    height: 40%;
    padding: .5rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(255,255,255,.2);
  }

   
  .modal2 {
    background-color: rgba(0,0,0,.2);
    backdrop-filter: blur(15px);
    width: 30%;
   
    margin: auto;
    padding: .5rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(255,255,255,.2);
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #C8F600;
  }
  
  .modal p {
    margin-bottom: 20px;
    color: white;
  }

  .modal2 h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #C8F600;
  }
  
  .modal2 p {
    margin-bottom: 20px;
    color: white;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    color: #C8F600;
  }
  
  .modal-buttons button {
    margin: 0 10px;
    padding: 8px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* background: rgba(255,255,255,1); */
    color: black;
  }

  .modal-buttons button:hover {
    margin: 0 10px;
    padding: 8px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(255,255,255,.5);
  }
  
  /* Additional styling for the main content */
  .FullScreen {
    position: relative;
    min-height: 100vh;
  }
  
  .AppContainer {
    padding: 20px;
  }
  

  
  .EndButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  /* .buttons {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
  }
   */


   #video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    z-index: -10
  }
  
  .overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(0,0,0,.9) 100%, rgba(0,0,0,.9) 100%);
    background-size: 3px 3px;
    z-index: -9;
  }
  
  .MainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
  }
  
  .FooterMain img {
      width: 200px;
  }

  .PlayButton {
    color: black;
    padding: .5rem;
    background: #999999;
    opacity: 0.6;
    border-radius: 10px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000000;

  }


  @media screen and (max-width: 860px) {
    .StoryCaption {
      font-size: 24px;
    }

    .background-container {
      display: flex;
      justify-content: center;
      min-width: 100vw;
      min-height: 20vh; /* Set a minimum height to fill the viewport */
      background: url('../assets/images/Background3.png') no-repeat center bottom fixed;
      background-size: cover;
    }


   }


