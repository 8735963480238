@import "https://fonts.googleapis.com/css?family=Carrois+Gothic";
.Desc {
  color: #510c0c;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  padding-left: 10%;
  padding-right: 10%;
  font-family: Indie Flower, cursive;
  font-size: 40px;
  font-style: normal;
  font-weight: 100;
}

.DescMint2, .font {
  color: #510c0c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  font-family: Indie Flower, cursive;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.DescMint3 {
  color: #510c0c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  font-family: Indie Flower, cursive;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.DescMint4 {
  color: #510c0c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  font-family: Indie Flower, cursive;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}

.DescMint5 {
  color: #510c0c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  font-family: Indie Flower, cursive;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
}

.DescMint6 {
  color: #14510c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  background-color: #fbfbfb;
  border: 2px solid #000;
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
  font-family: Indie Flower, cursive;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.MetavateLogo {
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
  display: flex;
}

.video {
  width: 900px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (width <= 860px) {
  .Desc {
    letter-spacing: 2.5px;
    color: #510c0c;
    text-align: center;
    text-transform: lowercase;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    font-family: Indie Flower, cursive;
    font-size: 10px;
    font-style: normal;
    font-weight: 100;
  }

  .DescMint2 {
    color: #510c0c;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: lowercase;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    font-family: Indie Flower, cursive;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }

  .DescMint3 {
    color: #510c0c;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: lowercase;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    font-family: Indie Flower, cursive;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
  }

  .video {
    width: 400px;
    height: 230px;
    margin-left: auto;
    margin-right: auto;
  }

  .HeaderLogo {
    width: 100%;
    height: auto;
  }

  .RoadMapLogo, .BioLogo, .AboutLogo, .WhatAreLogo {
    width: 85%;
  }
}

.collectionSlider {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.collectionSlider .collectionSlider-row {
  background-image: url("CollectionSlider.dfa8d4e2.png");
  background-size: 3898px 275px;
  width: 3898px;
  height: 275px;
  animation: 50s linear infinite slide;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -2842.5px;
  }
}

.bioSlider {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.bioSlider .bioSlider-row {
  background-image: url("BioSlider.3b3d3e2c.png");
  background-size: 2842.5px 275px;
  width: 2842.5px;
  height: 275px;
  animation: 50s linear infinite slide;
}

.MintContainter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.MintBox {
  color: #fff;
  background-color: #141414;
  border-radius: 20px;
  width: 300px;
  height: 200px;
  position: relative;
}

body {
  overflow-x: hidden;
}

#root {
  background-color: #fff0;
}

.MuiSlider-track {
  height: 4px !important;
}

.MuiSlider-rail {
  height: 3px !important;
}

#connect-btn {
  background-image: url("ConnectedWallet.13af033a.png");
}

#mint-button {
  background-image: url("MintButton.d3efb435.png");
}

#root {
  text-align: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
}

.App {
  text-align: center;
  text-align: center;
  text-transform: lowercase;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gap {
  width: 100%;
  height: 50px;
}

.DescMint {
  color: #510c0c;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: lowercase;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20%;
  padding-right: 20%;
  font-family: proxima-nova, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.walletButton {
  font-family: Indie Flower, cursive;
  font-size: 30px;
}

.walletButton2 {
  font-family: Indie Flower, cursive;
  font-size: 50px;
}

.SubTitle {
  color: #510c0c;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: proxima-nova, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

h3 {
  font-size: 4rem;
}

.HeaderLogo {
  width: 55%;
  height: auto;
}

.RoadMapLogo, .BioLogo, .AboutLogo {
  width: 35%;
}

.WhatAreLogo {
  width: 45%;
}

.TwitterLogo {
  cursor: pointer;
}

.EtherscanLogo {
  width: 32px;
  height: 32px;
}

.socialLinks {
  flex-direction: row;
  gap: 2rem;
  display: flex;
}

.metavate-p {
  font-family: Indie Flower, cursive;
  font-size: 2rem;
  position: relative;
  top: 40px;
  left: 0%;
}

.video {
  background: #fff;
  width: 900px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (width <= 860px) {
  .Desc {
    letter-spacing: 2.5px;
    color: #510c0c;
    text-align: center;
    text-transform: lowercase;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    font-family: Indie Flower, cursive;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .DescMint2 {
    color: #510c0c;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: lowercase;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    font-family: Indie Flower, cursive;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .DescMint3 {
    color: #510c0c;
    letter-spacing: 2.5px;
    text-align: center;
    text-transform: lowercase;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    font-family: Indie Flower, cursive;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
  }

  .video {
    background: #fff;
    width: 400px;
    height: 230px;
    margin-left: auto;
    margin-right: auto;
  }

  .HeaderLogo {
    width: 100%;
    height: auto;
  }

  .RoadMapLogo, .BioLogo, .AboutLogo, .WhatAreLogo {
    width: 85%;
  }
}

.background {
  z-index: -100;
  position: absolute;
}

.EnterButton {
  backdrop-filter: blur(10px);
  background: #00000080;
  border: 1px solid #ffffff4d;
  border-radius: 10px;
  width: 250px;
  max-width: 450px;
  padding: .5rem 1rem;
  position: absolute;
}

@font-face {
  font-family: matrix-code;
  src: url("http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.eot?#iefix") format("embedded-opentype"), url("http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.woff") format("woff"), url("http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.ttf") format("truetype"), url("http://neilcarpenter.com/demos/canvas/matrix/font/matrix-code.svg#svgFontName") format("svg");
}

@font-face {
  font-family: Art;
  src: url("OCR.675d99eb.ttf");
}

@font-face {
  font-family: Art2;
  src: url("big_noodle_titling.db157ca6.ttf");
}

.AppContainer {
  background: #fff;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.DarkContainer {
  color: #510c0c;
  z-index: 500;
  font-family: Indie Flower, cursive;
  cursor: url("https://ani.cursors-4u.net/cursors/cur-13/cur1161.ani"), url("https://ani.cursors-4u.net/cursors/cur-13/cur1161.png"), auto !important;
}

.DarkContainer h1 {
  width: 50%;
}

.background-container {
  background: url("Background3.5fc2e01e.png") bottom / cover no-repeat fixed;
  justify-content: center;
  min-width: 100vw;
  display: flex;
}

.AppContainer2 {
  height: auto;
  padding-top: 10rem;
  display: flex;
}

.NavLogo a {
  color: #fff;
  text-align: center;
  background: #141414;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin: 20px auto auto;
  padding: 10px 20px;
  font-size: 2rem;
  display: flex;
}

.NavLogo a:hover {
  color: #000;
  background: #59d600;
  transition: all .5s ease-in;
}

.StoryContainer {
  text-align: center;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 2.5rem;
  display: flex;
}

.fontArt {
  backdrop-filter: blur(15px);
  text-transform: uppercase;
  background: #0009;
  border: 2px solid #ffffff1a;
  font-family: Art;
}

.fontArtNum {
  backdrop-filter: blur(15px);
  text-transform: uppercase;
  background: #0009;
  border: 2px solid #ffffff1a;
  font-family: Art2;
}

.StoryContainer p {
  width: 100%;
  margin: auto;
}

.StoryCaption2 p {
  color: #c8f600;
  text-align: center;
  width: 100%;
}

.StoryCaption3 p {
  color: #c8f600;
  text-align: center;
  width: 100%;
  margin: 50px auto;
}

.StoryCaption4 p {
  color: #c8f600;
  text-align: center;
  width: 100%;
  margin: 50px auto;
  padding: 0 1rem;
  font-size: 2rem;
}

.StoryCaption5 p {
  color: #c8f600;
  text-align: center;
  width: 100%;
  margin: 50px auto;
  font-size: 2rem;
}

.StoryCaption {
  color: #c8f600;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  font-size: 40px;
  display: flex;
}

.StoryCaption p {
  padding: 3rem;
}

.StoryArt img {
  z-index: -1;
  width: 100vw;
}

.FullScreen {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.StoryButtons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin: auto;
  display: flex;
}

.buttons {
  width: 250px;
  height: auto;
}

.EndButtons {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.fontAlt {
  font-weight: 700;
}

.modal-overlay {
  background-color: #000000e6;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  backdrop-filter: blur(15px);
  background-color: #0003;
  border: 2px solid #fff3;
  border-radius: 8px;
  width: 40%;
  height: 40%;
  padding: .5rem;
  box-shadow: 0 0 10px #0000004d;
}

.modal2 {
  backdrop-filter: blur(15px);
  background-color: #0003;
  border: 2px solid #fff3;
  border-radius: 8px;
  width: 30%;
  margin: auto;
  padding: .5rem;
  box-shadow: 0 0 10px #0000004d;
}

.modal-content {
  text-align: center;
}

.modal h2 {
  color: #c8f600;
  margin-bottom: 10px;
  font-size: 24px;
}

.modal p {
  color: #fff;
  margin-bottom: 20px;
}

.modal2 h2 {
  color: #c8f600;
  margin-bottom: 10px;
  font-size: 24px;
}

.modal2 p {
  color: #fff;
  margin-bottom: 20px;
}

.modal-buttons {
  color: #c8f600;
  justify-content: center;
  display: flex;
}

.modal-buttons button {
  cursor: pointer;
  color: #000;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  padding: 8px 20px;
  font-size: 16px;
}

.modal-buttons button:hover {
  cursor: pointer;
  background: #ffffff80;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  padding: 8px 20px;
  font-size: 16px;
}

.FullScreen {
  min-height: 100vh;
  position: relative;
}

.AppContainer {
  padding: 20px;
}

.EndButtons {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

#video {
  z-index: -10;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}

.overlay {
  z-index: -9;
  background-image: linear-gradient(45deg, #000000e6 100% 100%);
  background-size: 3px 3px;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.MainContainer {
  text-align: center;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.FooterMain img {
  width: 200px;
}

.PlayButton {
  color: #000;
  opacity: .6;
  z-index: 1000000;
  background: #999;
  border-radius: 10px;
  padding: .5rem;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

@media screen and (width <= 860px) {
  .StoryCaption {
    font-size: 24px;
  }

  .background-container {
    background: url("Background3.5fc2e01e.png") bottom / cover no-repeat fixed;
    justify-content: center;
    min-width: 100vw;
    min-height: 20vh;
    display: flex;
  }
}
/*# sourceMappingURL=index.d8e74544.css.map */
